<template>
  <div class="flex min-h-screen flex-col">
    <HeaderBar />
    <NuxtLoadingIndicator />
    <div class="flex min-h-full flex-1 flex-col">
      <HeaderTitle  />
      <main class="flex min-h-full flex-1 flex-col">
        <div class="flex min-h-full min-h-full flex-1">
          <div
              class="mx-auto min-h-full max-w-7xl flex-1 px-4 py-4 lg:py-8 sm:px-0 sm:px-6 lg:px-8"
          >
            <slot />

          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script setup>

</script>
