<template>
  <header>
    <div
      class="mx-auto max-w-7xl bg-neutral-500 px-4 py-4 sm:px-6 lg:px-8"
      v-if="eventStore.selectedEvent !== undefined && eventStore.selectedEvent !== null"
    >
      <h1 class="text-3xl font-bold leading-tight tracking-tight text-white">
        {{ eventStore.selectedEvent.name }}
      </h1>
    </div>
  </header>
</template>

<script setup>
import { useEventStore } from "~/stores/EventStore";
const eventStore = useEventStore();
</script>

<style scoped></style>
